<template>
  <div class="gallery route__general">
    <h2 class="route__title">Galeri</h2>
    <div class="products__images">
      <div
        class="galleryImage__container"
        v-for="(name, index) in imageName"
        :key="index"
      >
        <img :src="require(`@/assets/images/${name}`)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  data() {
    return {
      imageName: [
        'gallery1.jpeg',
        'gallery2.jpg',
        'gallery3.jpg',
        'gallery4.jpeg',
        'gallery5.jpeg',
        'gallery6.jpeg',
        'gallery7.jpeg',
        'gallery8.jpeg',
        'gallery9.jpeg',
        'gallery10.jpeg',
        'gallery11.jpeg',
        'gallery12.jpeg',
        'gallery13.jpeg',
        'gallery14.jpeg',
        'gallery15.jpeg',
        'gallery16.jpeg',
        'gallery17.jpeg',
        'gallery18.jpeg',
        'gallery19.jpeg',
        'gallery20.jpeg',
        'gallery21.jpeg',
        'gallery22.jpeg'
      ]
    };
  },
  computed: {
    imagePath(name) {
      return require(`@/assets/images/${name}.jpeg`);
    }
  },
  metaInfo: {
    title: 'Galeri | Emka Radyatör',
    meta: [
      {
        name: 'description',
        content:
          'Her türlü iş makinası jeneratör, radyatör, klima, yağ soğutucu, intercooler hava kompresörü; imalat, bakım ve tamiri'
      },
      {
        name: 'keywords',
        content: 'radyatör, makina sanayi, makine, emka radyatör,'
      },
      { name: 'robots', content: 'index,follow' }
    ]
  }
};
</script>

<style></style>
